import React from 'react';
import { Form, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
// import 'bootswatch/dist/lux/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style.css';


export function NumberField(props) {
    const isInvalid = props.error;

    return (
        <Form.Group controlId={props.id} style={{ paddingTop: "20px" }}>
            <Form.Label className="text-dark"><strong>{props.label}</strong></Form.Label>
            <Form.Control
                type='number'
                name={props.id}
                value={props.value}
                onChange={props.onChange}
                min={props.min}
                max={props.max}
                isInvalid={isInvalid}
            />
            {isInvalid && (
                <Form.Control.Feedback type="invalid">
                    This field is required.
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
}


export function TextField(props) {
    const isInvalid = props.error;

    return (
        <Form.Group style={{ paddingTop: "20px" }} controlId={props.id}>
            <Form.Label className="text-dark">{props.label}</Form.Label>
            <Form.Control
                as={props.as}
                rows={props.rows}
                type="text"
                name={props.id}
                value={props.value}
                onChange={props.onChange}
                isInvalid={isInvalid}
            />
            {isInvalid && (
                <Form.Control.Feedback type="invalid">
                    This field is required.
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
}

export function DropdownField(props) {
    return (
        <Form.Group controlId={props.id}>
            <Form.Label className="text-dark" style={{ paddingTop: "20px" }}>{props.label}</Form.Label>
            <Form.Select name={props.id} onChange={props.onChange} value={props.value}>
                <option value='' />
                {props.options.map(o => <option key={o}>{o}</option>)}
            </Form.Select>
        </Form.Group>
    );
}

export function ButtonField(props) {
    const isInvalid = props.error;

    return (
        <Form.Group controlId={props.id}>
            <Form.Label style={{ paddingTop: '20px' }}><strong>{props.label}</strong></Form.Label>
            <div className={isInvalid ? 'border border-danger p-2 rounded' : 'p-2'}>
                <ToggleButtonGroup
                    type="radio"
                    onChange={(_, e) => props.onChange(e)}
                    name={props.id}
                    value={props.value}
                >
                    {props.options.map(o => (
                        <ToggleButton
                            id={`${props.id}-${o}`}
                            key={o}
                            value={o}
                            variant='outline-dark'
                        >
                            {o}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </div>
            {isInvalid && (
                <div className="text-danger mt-1" style={{ fontSize: '0.875em' }}>
                    This field is required.
                </div>
            )}
        </Form.Group>
    );
}


export function FileField(props) {
    return (
        <Form.Group style={{ paddingTop: "20px" }} controlId={props.id}>
            <Form.Label className="text-dark"><strong>{props.label}</strong></Form.Label>
            <Form.Control
                as={props.as}
                type='file'
                name={props.id}
                value={props.value}
                onChange={props.onChange}
                // style={{ color: 'white', background: 'grey', borderColor: 'lightgrey' }}
                multiple
            />
        </Form.Group>
    );
}