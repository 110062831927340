import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import "@fontsource/poppins";
import '../style.css';
import 'bootswatch/dist/lux/bootstrap.min.css';
import 'filepond/dist/filepond.min.css';

import VehicleData from './VehicleData';
import ValuationCard from './ValuationCard';
import FormPage1 from './FormPage1';
import FormPage2 from './FormPage2';
import FormPage3 from './FormPage3';
import FormPage4 from './FormPage4';

import { DEBUG } from '../index';
import { Modal, Button, Collapse } from 'react-bootstrap';
import { useWidget } from '../controllers/MainViewController';
import { getWidgetConfig } from '../util/api';

const pages = ['Contact', 'Car Info', 'Overview', 'Details', 'Valuation', 'Done!']

const MainView = (props) => {
  const { widgetId } = useParams();

  // Add a loading state to wait for valuationEnabled to be fetched
  const [valuationEnabled, setValuationEnabled] = useState(true);

  useEffect(() => {
    async function fetchShowValuationFlag() {
        if (!widgetId) return;
        try {            
            // Fetch widget settings
            const { widgetData, error } = await getWidgetConfig(widgetId);

            if (error) {
                console.error("[!] Error fetching widget config:", error);
                return;
            }

            if (widgetData && widgetData.SHOW_VALUATION !== undefined) {
                // console.log("Fetched SHOW_VALUATION:", widgetData.SHOW_VALUATION);
                setValuationEnabled(widgetData.SHOW_VALUATION === true);
            }
        } catch (error) {
            console.error("[!] Unexpected error fetching widget config:", error);
        }
    }

    fetchShowValuationFlag();
  }, [widgetId]);

  // Initialize widget state
  const initValue = {
    pageNumber: 1,
    pages,
    widgetId,
    estimated_arrival: "immediate",
    min_price: 0,
  };

  const { state, setState, nextPage, prevPage } = useWidget(initValue);

  // Update pages dynamically when valuationEnabled changes
  useEffect(() => {
    setState((prevState) => {
      const basePages = ['Contact', 'Car Info', 'Overview', 'Details'];
  
      // Dynamically add or remove pages based on flags
      if (valuationEnabled) {
        basePages.push('Valuation');
      }
  
      basePages.push('Done!'); // Always at the end
  
      return {
        ...prevState,
        pages: basePages
      };
    });
  }, [valuationEnabled, setState]);
  
  let fragment;
  let styling;

  if (!widgetId) {
    fragment = (
      <div className="center-flex">
        <h2>Something went wrong</h2>
      </div>
    );
    styling = 'p2-style';
  } else {
    switch (state.pageNumber) {
      case 1:
        fragment = (
          <FormPage1
            id='form-page-1'
            state={state}
            setState={setState}
            nextPage={nextPage}
            prevPage={prevPage}
          />
        );
        styling = 'p2-style';
        break;
      case 2:
        fragment = (
          <FormPage2
            id='form-page-1'
            state={state}
            setState={setState}
            nextPage={nextPage}
            prevPage={prevPage}
          />
        );
        styling = 'p2-style';
        break;
      case 3:
        fragment = (
          <VehicleData
            state={state}
            setState={setState}
            nextPage={nextPage}
            prevPage={prevPage}
          />
        );
        styling = 'p2-style';
        break;
      case 4:
        fragment = (
          <FormPage3
            id='form-page-2'
            state={state}
            setState={setState}
            nextPage={nextPage}
            prevPage={prevPage}
          />
        );
        styling = 'p2-style';
        break;
      case 5:
        if (valuationEnabled) {
          fragment = (
            <ValuationCard
              state={state}
              setState={setState}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          );
          styling = 'p2-style';
          break;
        }
        // If valuation is disabled, automatically advance to the next page.
        nextPage();
        return null;
      default:
        fragment = (
          <FormPage4
            state={state}
            setState={setState}
            nextPage={nextPage}
            prevPage={prevPage}
          />
        );
    }
  }

  return (
    <>
      <Modal show={true} backdrop={false} dialogClassName={styling}>
        {fragment}
        {DEBUG ? (
          <pre>
            <DebugOutput debug={JSON.stringify(state, null, '  ')} />
          </pre>
        ) : null}
      </Modal>
    </>
  );
};

function DebugOutput(props) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="debug-collapsible"
        aria-expanded={open}
      >
        Debug
      </Button>
      <Collapse in={open}>
        <div id="debug-collapsible">{props.debug}</div>
      </Collapse>
    </>
  );
}

export default MainView;
