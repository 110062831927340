import React from 'react';

function FormPage4(props) {
    return (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '60vh' }}>
            <div style={{
                display: 'flex',
                border: '1px solid #e0e0e0',
                borderRadius: '10px',
                padding: '30px 40px',
                maxWidth: '600px',
                width: '100%',
                backgroundColor: '#fff',
                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.05)'
            }}>
                <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: 600, fontSize: '1.2rem', lineHeight: 1.5 }}>
                        YOUR CAR<br />
                        HAS BEEN<br />
                        SUCCESSFULLY<br />
                        SUBMITTED.
                    </div>
                </div>
                <div style={{ paddingLeft: '30px', flex: 1 }}>
                    <div style={{ fontSize: '0.95rem', color: '#555' }}>
                        Please follow SMS<br />
                        instructions to<br />
                        download the app and<br />
                        manage your listing.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FormPage4;
