import React from 'react';
import { Col, Card, Row, Stack, Button, Modal } from 'react-bootstrap';
import CustomIcon from '../view/CustomIcon';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import VehicleInfoCard from './VehicleInfoCard';
import ProgressBar from '../view/ProgressBar';
import { ClientInfo } from '../model/ClientDeal';
import { loadVehicleData } from '../util/api.js';

class VehicleData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rego: props.state.rego,
            regoLocState: props.state.regoLocState,
            pageNumber: props.state.pageNumber,
            pages: props.state.pages,
        }
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    async componentDidMount() {
        console.log('Vehicle Data request', this.state);
        const result = await loadVehicleData(this.state);
        console.log('Vehicle Data Mount result', result);
        this.setState(result);
    }

    render() {
        let content;
        console.log('render: ', this.state);

        if (this.state.loading) {
            content = (
                <>
                    <div className="center-flex">
                        <div>Looking for your Vehicle...</div>
                    </div>
                    {/* <Col>
                        <Card style={{ width: "60%",  height: "10em", justifyContent: "centrer" }}>
                            <Card.Body>
                                <Card.Title className="card-title">
                                    Looking for your Vehicle...
                                </Card.Title>
                                <Card.Text>
                                    <Row>
                                        <Col style={{ display: "flex", justifyContent: "center" }}>
                                            <ThreeCircles color="rgb(177,178,181)" height={50} width={50} />
                                        </Col>
                                    </Row>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col> */}
                </>
            )
        } else if (!this.state.error) {
            console.log('vehicleData state: ', this.state);
            const {
                year,
                make,
                model,
                description,
                cylinders,
                engine_size,
                fuel_type,
                transmission,
                year_options,
                make_options,
                model_options,
                description_options,
                cylinders_options,
                engine_size_options,
                fuel_type_options,
                transmission_options,
            } = this.state.vehicleData;

            console.log('status render: ', JSON.stringify(this.state.vehicleData));

            content = (
                <>
                    <Col>
                        <Stack>
                            {this.state.vehicleData.description_options ? (
                                this.state.vehicleData.description_options.map((_, i) => (
                                    <div key={`optionChoice-${i}`} onClick={(e) => this.setParentState('vehicleOption', i, e)}>
                                        <VehicleInfoCard
                                            year={year_options ? year_options[i] : year}
                                            make={make_options ? make_options[i].name : make.name}
                                            model={model_options ? model_options[i].name : model.name}
                                            description={description_options ? description_options[i] : description}
                                            engine_size={engine_size_options ? engine_size_options[i] : engine_size}
                                            cylinders={cylinders_options ? cylinders_options[i] : cylinders}
                                            fuel_type={fuel_type_options ? fuel_type_options[i] : fuel_type}
                                            transmission={transmission_options ? transmission_options[i] : transmission}
                                        />
                                    </div>
                                ))
                            ) : (
                                <VehicleInfoCard
                                    year={year}
                                    make={make ? make.name : 'Undefined'}
                                    model={model ? model.name : 'Undefined'}
                                    description={description}
                                    engine_size={engine_size}
                                    cylinders={cylinders}
                                    fuel_type={fuel_type}
                                    transmission={transmission}
                                />
                            )}
                        </Stack>
                    </Col>

                </>);
        };

        return (
            <>
                <Modal.Header>
                    <Col>
                        <Modal.Title>
                            Here's what we've found
                        </Modal.Title>
                    </Col>
                    <Col>
                        <ProgressBar pageNumber={this.state.pageNumber} pages={this.state.pages} />
                    </Col>
                </Modal.Header>
                <Modal.Body>

                    <Row style={{ marginTop: '30px' }}>
                        {this.state.error ? (
                            <div>Vehicle cannot be found. Please go back and check the vehicle details.</div>
                        ) : (
                            content
                        )}
                    </Row>

                    <Row className='row-buttons'>
                        {/* GO BACK Button (Always Visible) */}
                        <Col className='row-button-left'>
                            <Button
                                type="button"
                                variant='outline-dark'
                                onClick={this.props.prevPage}>
                                <CustomIcon icon={faAngleLeft} /> GO BACK
                            </Button>
                        </Col>

                        {/* NEXT Button (Only Visible If this.state is Truthy */}
                        {this.state.vehicleData && (
                            <Col className='row-button-right'>
                                <Button
                                    variant="outline-dark"
                                    onClick={this.props.nextPage}>
                                    NEXT <CustomIcon icon={faAngleRight} />
                                </Button>
                            </Col>
                        )}
                    </Row>

                </Modal.Body>
            </>
        )
    }

}

export default VehicleData;