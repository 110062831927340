import { useState, useCallback } from 'react';
// npm install dotenv
import 'dotenv/config';

console.log('[*] getting params from env file...');
const BACKEND_BASE = process.env.REACT_APP_BACKEND_BASE || "/api";
console.log('[x] Got BACKEND_BASE =', BACKEND_BASE);

const useDynamo = (initialValue) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getDynamo = useCallback(async (state) => {
        setLoading(true);
        setError(null);
        console.log('ingeststate', state);
        console.log(`${BACKEND_BASE}/leads/${state.widgetId}/${state.phone}`);
        try {
            const lead = await fetch(
                `${BACKEND_BASE}/leads/${state.widgetId}/${state.phone}`,
                {
                    method: 'GET',
                }
            );

            let data;
            console.log('leadok', lead.ok);
            if (!lead.ok) {
                data = false;
            } else {
                data = await lead.json();
                console.log('returnedata', data);
            }
            return data; 
        } catch (error) {
            console.error('Error: ', error);
            setError(error);
        } finally {
            setLoading(false);
        }
    }, []);

    const saveDynamo = useCallback(async (state) => {
        setLoading(true);
        setError(null);
        let deal;
        try {
            console.log('Fetching...', JSON.stringify(state))
            deal = await fetch(
                `${BACKEND_BASE}/leads/${state.widgetId}`,
                {
                    method: 'POST',
                    body: JSON.stringify(state),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log('Fetched!', deal)

            // if (!deal.ok) {
            //     throw new Error(`HTTP error! status: ${deal.status}`);
            // }

            let dealData = await deal.json();
            console.log(`saveDynamo Deal data>> ${JSON.stringify(dealData)}`);
            console.log("[x] Deal Creation request complete!");
            return dealData;
        } catch (error) {
            console.error('Error:', error);
            setError(error);
        } finally {
            setLoading(false);
        }
    }, []);

    return { getDynamo, saveDynamo, loading, error };
};


const loadVehicleData = async (state) =>  {
    try {
        const { rego, regoLocState } = state;
        console.log('Vehicle Data State >>', state);

        console.log("[*] Checking that the vehicle exists....");

        console.log('vehicle lookup', rego, regoLocState);
        const url = `${BACKEND_BASE}/vlookup/${regoLocState}/${rego}`
        console.log(url);
        let data;
        try { 
            const vehicle = await fetch(
                url,
                {
                    method: 'GET', 
                }
            )
    
            console.log('vehicle', vehicle.ok);
            if (!vehicle.ok) { 
                data = false ;
            }
            else { 
                data = await vehicle.json();
                console.log('returnedata', data);
            }
        }
        catch (error){
            console.error('Error: ',error)
        }

        console.log("[x] Vehicle found!");

        return {
            vehicleData: data,
            loading: false
        };
    } catch (error) {
        console.error("Error loading vehicle data:", error);
        return {
            loading: false,
            error: error.message
        };
    }
}

const getWidgetConfig = async (widgetId) => {
    try {
        console.log("[*] Fetching Widget Configuration: ", widgetId);

        const url = `${BACKEND_BASE}/widgets/${widgetId}/config`;
        console.log(`[*] Fetching from URL: ${url}`);

        let data = null;

        try {
            const response = await fetch(url, { method: 'GET' });

            // console.log("[*] Raw Response:", response); // Log response object

            if (!response.ok) {
                console.error(`[!] Failed to fetch widget config. HTTP Status: ${response.status}`);
                return { widgetData: null, error: `HTTP Error ${response.status}` };
            }

            const contentType = response.headers.get("content-type");
            if (!contentType || !contentType.includes("application/json")) {
                console.error("[!] Error: Response is not JSON");
                return { widgetData: null, error: "Invalid JSON response from server" };
            }

            data = await response.json();
            console.log("[x] Widget config retrieved successfully!");
            // console.log("[x] Widget config retrieved successfully:", data);
        } catch (error) {
            console.error("[!] Fetch request error:", error);
            return { widgetData: null, error: "Network error while fetching widget config" };
        }

        return {
            widgetData: data,
            error: null
        };
    } catch (error) {
        console.error("[!] Error processing widget config:", error);
        return {
            widgetData: null,
            error: error.message
        };
    }
};



export { 
    loadVehicleData, 
    useDynamo,
    getWidgetConfig
};