import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Col } from 'react-bootstrap';

// Smaller styles for active and inactive badges
const activeBadgeStyle = {
  backgroundColor: '#d9534f', // red background
  color: '#fff',
  border: '1px solid #d9534f',
  borderRadius: '16px',
  padding: '4px 12px',
  fontWeight: 'bold',
  fontSize: '0.875rem',
};

const inactiveBadgeStyle = {
  backgroundColor: '#fff',
  color: '#555',
  border: '1px solid #ccc',
  borderRadius: '16px',
  padding: '4px 12px',
  fontWeight: 'bold',
  fontSize: '0.875rem',
};

function ProgressBar({ pages, pageNumber }) {
  const containerRef = useRef(null);
  const [lineStyle, setLineStyle] = useState({});

  const recalcLine = useCallback(() => {
    if (!containerRef.current) return;
    const badges = containerRef.current.querySelectorAll('.progress-badge');
    if (!badges.length) return;

    const containerRect = containerRef.current.getBoundingClientRect();
    const topPositions = Array.from(badges).map(
      (badge) => badge.getBoundingClientRect().top
    );
    const firstTop = topPositions[0];

    const allSameRow = topPositions.every((top) => Math.abs(top - firstTop) < 5);
    if (!allSameRow) {
      setLineStyle({ display: 'none' });
      return;
    }

    // Get badge positions dynamically
    const firstBadge = badges[0];
    const lastBadge = badges[badges.length - 1];
    const firstRect = firstBadge.getBoundingClientRect();
    const lastRect = lastBadge.getBoundingClientRect();

    const left = firstRect.left - containerRect.left + firstRect.width / 2;
    const right = lastRect.left - containerRect.left + lastRect.width / 2;
    const width = Math.max(0, right - left); // Ensure width is never negative

    setLineStyle({
      position: 'absolute',
      top: '50%',
      left: `${left}px`,
      width: `${width}px`,
      borderTop: '4px solid #ccc',
      transform: 'translateY(-50%)',
      zIndex: 0,
      display: 'block',
    });
  }, [pages, pageNumber]);

  useEffect(() => {
    recalcLine();
    window.addEventListener('resize', recalcLine);
    return () => window.removeEventListener('resize', recalcLine);
  }, [recalcLine]);

  return (
    <Col style={{ width: '100%' }}>
      <div
        ref={containerRef}
        style={{
          position: 'relative',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '40px', // Adjusted for better responsiveness
          padding: '10px 20px',
          boxSizing: 'border-box',
        }}
      >
        <div style={lineStyle} />

        {pages.map((p, i) => (
          <div
            key={`badge-${i}`}
            className="progress-badge"
            style={{ position: 'relative', zIndex: 1 }}
          >
            <span style={i < pageNumber ? activeBadgeStyle : inactiveBadgeStyle}>
              {p}
            </span>
          </div>
        ))}
      </div>
    </Col>
  );
}

export default ProgressBar;
