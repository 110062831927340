const FILE_URL = 'https://www.carlogos.org/car-logos/';

export function getCarLogo(make) {
    return `${FILE_URL}${make === 'default' ? 'porsche' : make}-logo.png`;
}

export function validatePhoneNumber(input) {    
    const digitsOnly = input.replace(/\D/g, '');
    
    if (digitsOnly.length <= 10) {
        return digitsOnly;
    } else {
        return digitsOnly.slice(0, 10);
    }
}

export function generateDealPayload (state) {

    let requestPayload = {
        vehicleinfo: {
            rego: state.rego
            ,registrationstate: state.regoLocState
            ,carlocationpostcode: state.carLocPostcode
        }
        , vehiclecondition: {
            odometer: state.odometer
            ,tyres : state.tyres
            ,interior_condition: this.state.interior_condition.toLowerCase().replaceAll(' ','_')
            ,exterior_condition: this.state.exterior_condition.toLowerCase().replaceAll(' ','_')
            ,keys: this.state.keys 
            ,books: this.state.books.toLowerCase().replaceAll(' ','_')
            ,service_history: this.state.service_history.toLowerCase().replaceAll(' ','_')
            ,comments: this.state.comments
            ,is_imported: false 
            ,car_damage: false
            ,delivery_period: this.state.estimated_arrival.toLowerCase().replaceAll(' ','_').replaceAll('-','_')
        }
        , listingsettings: {
            sale_type: "customer_direct_listing"
            ,listing_duration: 24
        }        
    };
    return requestPayload;
}

export function stateSerialiser(state) {
    return {
        vehicleinfo: {
            rego: state.rego,
            registrationstate: state.regoLocState,
            carlocationpostcode: state.carLocPostcode
        },
        vehiclecondition: {
            odometer: state.odometer,
            tyres: state.tyres > 0 ? `Replace ${state.tyres}` : 'No replacement',
            interior_condition: state.interior_condition.toLowerCase().replaceAll(' ', '_'),
            exterior_condition: state.exterior_condition.toLowerCase().replaceAll(' ', '_'),
            keys: state.keys,
            books: state.books.toLowerCase().replaceAll(' ', '_'),
            service_history: state.service_history.toLowerCase().replaceAll(' ', '_'),
            comments: state.comments,
            is_imported: false,
            car_damage: false,
            delivery_period: "Immediate"
        },
        listingsettings: {
            sale_type: "valuation",
            listing_duration: 24
        }
    };
}

export function buildPageFlow(flags) {
    const pages = ['Contact', 'Car Info', 'Overview', 'Details'];
  
    if (flags.valuationEnabled) {
      pages.push('Valuation');
    }
  
    // Future: Add more conditional pages here
  
    pages.push('Done!');

    return pages;
  }
  