import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import MainView from './components/MainView';
import FormPage4 from './components/FormPage4';

require('dotenv').config(); // load .env

export const DEBUG = false;

// List of valid widget IDs
const validWidgetIds = ['5420133a-0472-433c-88f7-7693da562e0c', '2f89ba4b-6e4f-4663-80a3-e92031353a39']; // Add your valid widget IDs here

// Error page component
const ErrorPage = () => (
    <Modal show={true} backdrop={false}>
        <div className="center-flex">
            <h2>Something went wrong</h2>
        </div>
    </Modal>
);

// Component to validate widget ID
const ValidatedMainView = () => {
  const { widgetId } = useParams();

  if (!validWidgetIds.includes(widgetId)) {
      return <ErrorPage />; 
  }

  return <MainView />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Router>
        <Routes>
            <Route path="/:widgetId" element={<ValidatedMainView />} />
            <Route path="/" element={<ErrorPage />} />
            <Route path="/form4" element={<FormPage4 />} />
        </Routes>
    </Router>
);
